import { AngularEnvironmentModel } from 'src/app/shared/models/generic.models';

export const environment: AngularEnvironmentModel = {
  production: true,
  beta: false,
  qa: false,
  marketplaceOneUI: 'https://marketplaceone.figmarketing.com/#',
  iComplyUI: 'https://icomply.io/#',
  truelyticsSsoUrl: 'https://app.truelytics.com/user/sso?auto=true&id=',
  aspidaSso: 'https://sso.aspida.com/',
  equitrustAppLink: 'https://agents.equitrust.com/',
  amplitudeV1ApiKey: '45e8b609a44e2356fbb4366fa6840b76', // TODO: Remove when finish migration to backend
  amplitudeV2ApiKey: '8e9b9c64e7df8969a3536a3d29bc103a' // TODO: Switch to this key when ready to populate prod v2 data: 8e9b9c64e7df8969a3536a3d29bc103a
};